<template>
    <div class="c-app">
        <TheSidebar/>
        <CWrapper>
            <TheHeader v-if="$root.hasAccess('header')" @help-change="handleHelpChange"/>
            <TheEmptyHeader v-else />

            <div class="c-body">
                <main class="c-main">
                    <CContainer fluid>
                        <transition name="fade" mode="out-in">
                            <router-view :key="$route.path"></router-view>
                        </transition>
                    </CContainer>
                </main>
            </div>
            <TheFooter/>
        </CWrapper>
        <TheHelp :show-help="showHelp" :module="current_module"/>
    </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheEmptyHeader from './TheEmptyHeader'
import TheFooter from './TheFooter'
import TheHelp from './TheHelp'
import menuData from './_nav'

export default {
    name: 'TheContainer',
    components: {
        TheSidebar,
        TheHeader,
        TheEmptyHeader,
        TheFooter,
        TheHelp
    },
    data() {
        return {
            showHelp: false,
            current_module: 'project',
        }
    },
    watch: {
        $route(to, from) {
            this.showHelp = false;
        }
    },
    created() {
    },
    methods: {
        handleHelpChange(value) {
            this.showHelp = value;
            let menuStructure = JSON.parse(JSON.stringify(menuData))

            if (this.$route.path.indexOf('multichannel') > 0) {
                this.current_module = 'multichannel'
            } else {
                Object.keys(menuStructure).forEach(key => {
                    let item = menuStructure[key]
                    if (item.items) {
                        item.items.forEach(subItem => {
                            if (subItem.to == this.$route.path || subItem.href == this.$route.path) {
                                this.current_module = subItem.module
                            }
                        })
                    } else {
                        if (item.to == this.$route.path || item.href == this.$route.path) {
                            this.current_module = item.module
                        }
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>