<template>
    <CHeader fixed with-subheader light>
        <CSubheader class="px-3">
            <b-col cols="6">
                <ul class="list-group list-group-horizontal">
                    <li class="list-group-item border-0 d-lg-none">
                        <CToggler
                            in-header
                            class="d-lg-none mt-2"
                            @click="$store.commit('toggleSidebarMobile')"
                        />
                        <CToggler
                            in-header
                            class="d-md-down-none mt-2"
                            @click="$store.commit('toggleSidebarDesktop')"
                        />
                    </li>
                </ul>
            </b-col>
            <b-col cols="6" class="mt-1 p-0">
                <ul class="list-group list-group-horizontal" style="justify-content: end">
                    <li class="list-group-item  border-0 text-right">
                        <b-dropdown class="mt-0"
                                    left
                                    variant="warning"
                                    size="sm"
                                    right
                        >
                            <template #button-content>
                                <font-awesome-icon class="mr-2" icon="user" role="button"/>
                                <span class="d-md-down-none">
                                <strong v-if="getMe().vorname.length+getMe().name.length>0">{{
                                        getMe().vorname
                                    }} {{ getMe().name }}</strong>
                                <strong v-else>{{ getMe().email }}</strong>
                                </span>
                            </template>
                            <b-dropdown-item @click.prevent="$router.push({path: '/logout'}).catch(() => {})">
                                <font-awesome-icon class="mr-2" icon="power-off"/>
                                {{ $t('common.menu.logout') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </li>
                </ul>
            </b-col>
        </CSubheader>
    </CHeader>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'TheEmptyHeader',
    mounted: function () {
    },
    data() {
        return {}
    },
    beforeDestroy() {
    },
    watch: {},
    methods: {
        ...mapGetters('CommonData', ['getMe']),
    }
}
</script>