<template>
    <CSidebar
        fixed
        :minimize="minimize"
        :show="show"
        @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    >
        <CSidebarBrand class="d-md-down-none" to="/">
            <span>
                <img class="c-sidebar-brand-full" src="./../assets/erika-logo.png">
                <img class="c-sidebar-brand-minimized" src="./../assets/erika-logo2.png">
            </span>
        </CSidebarBrand>

        <CRenderFunction flat :content-to-render="menuItems()"/>
        <div class="pr-2 pl-2" style="overflow-y: auto;max-height: 150px">
            <b-progress v-for="(jobGroup,key) in getUserJobs()"
                        :key="key"
                        :max="jobGroup.jobs_count"
                        class="mb-1"
                        :id="'job_'+key"
            >
                <b-progress-bar :value="jobGroup.jobs_executed - jobGroup.failed_count"
                                :striped="jobGroup.status=='handled' || jobGroup.status=='queued'"
                                :animated="jobGroup.status=='handled'"
                                :variant="(jobGroup.status=='handled' || jobGroup.status=='queued') ? 'primary' : 'success'"
                ></b-progress-bar>
                <b-progress-bar :value="jobGroup.jobs_count - jobGroup.jobs_executed"
                                variant="secondary"
                ></b-progress-bar>
                <b-progress-bar :value="jobGroup.failed_count"
                                variant="danger"
                ></b-progress-bar>
                <b-popover :target="'job_'+key" triggers="hover focus">
                    <div>
                        <b-row>
                            <b-col class="text-right"><strong>Job:</strong></b-col>
                            <b-col>{{ jobGroup.type }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right"><strong>Status:</strong></b-col>
                            <b-col>{{ jobGroup.status }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right"><strong>Jobs:</strong></b-col>
                            <b-col>{{ jobGroup.jobs_count }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right"><strong>Executed:</strong></b-col>
                            <b-col>{{ jobGroup.jobs_executed }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right"><strong>Failed:</strong></b-col>
                            <b-col>{{ jobGroup.failed_count }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right"><strong>Created:</strong></b-col>
                            <b-col>{{ jobGroup.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}</b-col>
                        </b-row>
                        <b-row v-if="jobGroup.id">
                            <b-col>
                                <b-button size="sm"
                                          @click="$root.$children[0].openModal('job-info-modal', {job_group: jobGroup.group, job_id: jobGroup.id})"
                                          block
                                >Details
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-popover>
            </b-progress>
        </div>
        <CSidebarMinimizer
            class="d-md-down-none"
            @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
        />
    </CSidebar>
</template>

<script>
import menuData from './_nav'
import {mapGetters} from 'vuex'

export default {
    name: 'TheSidebar',
    data() {
        return {
            pluginActions: [],
            interval: null,
        }
    },
    mounted: function () {
        this.getPlugins().forEach(pluginName => {
            if (window[pluginName] && window[pluginName].methods.getHeaderActions) {
                window[pluginName].methods.getHeaderActions().forEach((element) => {
                    this.pluginActions.push(element);
                });
            }
        })

        this.interval = window.setInterval(() => {
            this.userJobs()
        }, 5000)
    },
    beforeDestroy() {
        clearInterval(this.interval)
        this.interval = null
    },
    methods: {
        ...mapGetters('CommonData', ['getModules', 'getSources', 'getPlugins', 'getCommonData']),
        ...mapGetters('I18NStore', ['languages']),
        ...mapGetters('Jobs', ['getUserJobs']),
        getMenuByName(menu, name) {
            return menu.filter(item => {
                return item.name == name
            })[0]
        },
        userJobs() {
            this.$store.dispatch('Jobs/fetchUserJobsStatus')
                .then((result) => {
                })
        },
        menuItems() {
            let menuStructure = JSON.parse(JSON.stringify(menuData))
            let nav = [{
                _name: 'CSidebarNav',
                _children: [],
            }]

            // Add dynamic routes
            this.getSources().forEach(source => {
                menuStructure['multichannel'].items.push({
                    name: source.api_type,
                    to: '/multichannel/' + source.id,
                    module: 'multichannel'
                })
            })

            this.getPlugins().forEach(pluginName => {
                const plugin = window[pluginName] || null;
                if (plugin && plugin.methods.getMenuStructure) {
                    plugin.methods.getMenuStructure(this.getCommonData())
                        .forEach((componentStructure, index) => {
                            if (componentStructure.keep) {
                                componentStructure.sub_menu.forEach(item => {
                                    if (menuStructure[componentStructure.name]) {
                                        menuStructure[componentStructure.name].items.push(
                                            {
                                                name: item.name,
                                                label: item.label,
                                                module: 'plugin',
                                                plugin: pluginName,
                                                to: item.to,
                                            }
                                        )
                                    }
                                })
                            } else {
                                menuStructure[componentStructure.name] = {
                                    name: componentStructure.name,
                                    label: componentStructure.label,
                                    module: 'plugin',
                                    plugin: pluginName,
                                    icon: componentStructure.icon,
                                    to: componentStructure.to,
                                    ordering: componentStructure.ordering || (500 + index),
                                }

                                if (componentStructure.sub_menu) {
                                    menuStructure[componentStructure.name].items = []
                                    componentStructure.sub_menu.forEach(item => {
                                        menuStructure[componentStructure.name].items.push(
                                            {
                                                name: item.name,
                                                label: item.label,
                                                module: 'plugin',
                                                plugin: pluginName,
                                                to: item.to,
                                            }
                                        )
                                    })
                                }
                            }
                        })
                }
            })

            let menuSortingOverride = {};

            Object.keys(menuStructure).forEach(key => {
                if (menuSortingOverride[key]) {
                    menuStructure[key].ordering = menuSortingOverride[key]
                }
            })

            let menuSorted = Object.keys(menuStructure).sort(function (a, b) {
                return menuStructure[a].ordering - menuStructure[b].ordering
            })

            menuSorted.forEach(key => {
                let item = menuStructure[key]
                if (item.type) {
                    nav[0]['_children'].push({
                        _name: 'CSidebarNavDivider',
                        _class: 'm-2'
                    })
                } else if (item.items) {
                    let hasAccess = false
                    item.items.forEach(subItem => {
                        if (this.$root.hasAccess(subItem.module) && (this.getModules().includes(subItem.module) || subItem.href || subItem.module == 'languages' || (subItem.module == 'plugin' && this.$root.pluginHasAccess(subItem.plugin, 'read')))) {
                            hasAccess = true
                        }
                    })
                    if (hasAccess) {
                        let subMenu = {}

                        item.items.forEach((subItem, index) => {
                            if (this.$root.hasAccess(subItem.module) && (this.getModules().includes(subItem.module) || subItem.href || subItem.module == 'languages' || (subItem.module == 'plugin' && this.$root.pluginHasAccess(subItem.plugin, 'read')))) {
                                let subChild = {}
                                if (key == 'languages') {
                                    subChild = {
                                        _name: 'CustomCSidebarNavItem',
                                        name: subItem.name,
                                        to: this.$router.currentRoute.path + '?locale=' + subItem.locale
                                    }
                                } else {
                                    subChild = {
                                        _name: 'CustomCSidebarNavItem',
                                        name: subItem.label || this.$t('common.menu.' + key + '.' + subItem.name),
                                    }
                                    if (this.getModules().includes(subItem.module) && subItem.to || (subItem.module == 'plugin' && this.$root.pluginHasAccess(subItem.plugin, 'read'))) {
                                        subChild['to'] = subItem.to
                                    } else {
                                        subChild['href'] = subItem.href
                                    }
                                }

                                subMenu[subChild.name + index] = subChild
                            }
                        })

                        let subMenuSorted = Object.keys(subMenu).sort(function (a, b) {
                            return subMenu[a].name.localeCompare(subMenu[b].name)
                        })

                        let route = []
                        if (item.to) {
                            route.push(item.to)
                        }
                        let subMenuItems = []
                        subMenuSorted.forEach(key => {
                            subMenuItems.push(subMenu[key])
                            route.push(subMenu[key].to)
                        })

                        let child = {
                            _name: 'CustomCSidebarNavDropdown',
                            name: (item.label || this.$t('common.menu.' + key + '.' + key)),
                            fontIcon: item.icon,
                            to: item.to,
                            routes: route,
                            items: subMenuItems
                        }

                        nav[0]['_children'].push(child)
                    }
                } else {
                    if (
                        this.$root.hasAccess(item.module) && (this.getModules().includes(item.module) || item.href)
                        || (item.module == 'plugin' && this.$root.pluginHasAccess(item.plugin, 'read'))
                    ) {
                        let child = {
                            _name: 'CustomCSidebarNavItem',
                            name: item.label || this.$t('common.menu.' + key),
                            fontIcon: item.icon,
                        }

                        if ((this.getModules().includes(item.module) || item.module == null || item.module == 'plugin') && item.to) {
                            child['to'] = item.to
                        } else {
                            child['href'] = item.href
                        }
                        nav[0]['_children'].push(child)
                    }
                }
            })

            return nav
        }
    },
    computed: {
        show() {
            return this.$store.state.sidebarShow
        },
        minimize() {
            return this.$store.state.sidebarMinimize
        }
    }
}
</script>
