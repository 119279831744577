<template>
    <CFooter :fixed="false">
        <div>
            <span class="ml-1">&copy; {{ new Date().getFullYear() }} eRIKA — smart digital solution</span>
        </div>
        <div class="mfs-auto">
            <span class="mr-1" target="_blank">Powered by</span>
            <a target="_blank" href="https://zentralweb.de/">ZentralWeb</a>
        </div>
    </CFooter>
</template>

<script>
export default {
    name: 'TheFooter'
}
</script>
