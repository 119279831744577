<template>
    <div class="right_sidebar" v-if="showHelp">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row class="m-0 p-2">
            <b-col cols="12">
                <h3 class="text-center">{{ $t('common.label.help') }}</h3>
                <div v-html="helpText">
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'TheHelp',
    props: {
        'showHelp': {
            default: false,
        },
        'module': {
            default: 'project',
        },

    },
    data() {
        return {
            loading: false,
            helpText: '',
        }
    },
    watch: {
        module: function (val) {
            this.loading = true;
            this.$store.dispatch('CommonData/fetchHelpData', val).then((response) => {
                this.loading = false;
                this.setHelpText();
            })
        },
    },
    methods: {
        ...mapGetters('CommonData', ['getHelpData']),
        setHelpText() {
            this.helpText = this.getHelpData()


        }
    },
}
</script>